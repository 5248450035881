import React, { useState, ChangeEvent, FormEvent } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Button, TextField, AppBar, Toolbar, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

function App() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <img src={logo} className="App-logo" alt="logo" style={{ marginRight: 'auto' }} />
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            BFOUR
          </Typography>
          <Button color="inherit">Home</Button>
          <Button color="inherit">About</Button>
          <Button color="inherit">Contact</Button>
        </Toolbar>
      </AppBar>
      <header className="App-header">
        <div className="content-space">
          <h1>Welcome to BFOUR</h1>
          <p> content here</p>
        </div>
        <h1>Contact Us</h1>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField 
            id="outlined-basic" 
            label="Name" 
            variant="outlined" 
            name="name" 
            value={formData.name} 
            onChange={handleInputChange} 
          />
          <TextField 
            id="filled-basic" 
            label="Email" 
            variant="filled" 
            name="email" 
            value={formData.email} 
            onChange={handleInputChange} 
          />
          <TextField 
            id="standard-multiline" 
            label="Message" 
            variant="standard" 
            name="message" 
            multiline 
            rows={4} 
            value={formData.message} 
            onChange={handleInputChange} 
          />
          <Button variant="contained" endIcon={<SendIcon />} type="submit">
            Send
          </Button>
        </form>
      </header>
    </div>
  );
}

export default App;
